import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO
        title='About'
        description='.'
        keywords=''
      />
      <Header activelink='about' />

      <>

        <section className="contact-hero-01">

          <div className="container-fluid">
            <div className="contact-01-hero-content">
              <div className="style-heading">
                <h2
                  data-w-id="453bc444-c787-50e2-0ed8-b0e6b7f42429"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d"
                  }}
                  className="style-hero-title heading-01 black-text"
                >
                  About Talentizo
                </h2>
                <div
                  data-w-id="aa6407e1-8822-8c7f-f632-c945ce88f6e3"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d"
                  }}
                  className="about-sub-text body-01"
                >
                  Tailored AI Learning for Every Stage

                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="about-details">
          <div className="container-fluid">
            <div className="home-message" >

              <div className="w-richtext">
                <blockquote>
                  Talentizo is an innovative company dedicated to advancing AI education and skills development across various industries. Founded by two visionaries in the tech and HR fields, Manish Shrivastava and Megha Punia, Talentizo aims to bridge the gap between current educational frameworks and the evolving demands of the workforce. The company offers a range of training programs designed to equip students, professionals, and organizations with the knowledge and tools needed to thrive in an AI-driven world.
                </blockquote>

              </div>
            </div>
          </div>
        </section> */}


        {/* <section className="content-04">
          <div className="container-fluid">
            <div className="content-wrap-04">

              <div class="vision-block">
                <div class="icon">
                  <img src="images/missin.png" alt="Vision Icon" />
                </div>
                <div class="content">
                  <h2>Our Mission</h2>
                  <p>To empower individuals and organizations by providing comprehensive AI training that enhances skills, fosters innovation, and drives digital transformation.</p>
                </div>
              </div>
              <div class="vision-block">
                <div class="icon">
                  <img src="images/vision.png" alt="Vision Icon" />
                </div>
                <div class="content">
                  <h2>Our Vision</h2>
                  <p>To become a global leader in AI education, enabling people to harness the full potential of AI technologies for personal and professional growth.</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}


        <section className="about-gelleray">
          <div className="gellery-container">
            <div className="gellery-content">
              <div
                data-w-id="aa6f0853-1678-8094-4eca-108d211553de"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d"
                }}
                className="gellary-item"
              >
                <img
                  src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556710_rectangle_42257.webp"
                  loading="lazy"
                  sizes="(max-width: 1439px) 25vw, 360px"
                  srcSet="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556710_rectangle_42257-p-500.webp 500w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556710_rectangle_42257.webp 584w"
                  alt=""
                  className="gellary-item-img"
                />
              </div>
              <div
                data-w-id="f9b8db21-689f-b71c-18a9-e53094fed6cc"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d"
                }}
                className="gellary-item _02"
              >
                <img
                  src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556711_rectangle_42259.webp"
                  loading="lazy"
                  sizes="(max-width: 1439px) 50vw, 720px"
                  srcSet="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556711_rectangle_42259-p-500.webp 500w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556711_rectangle_42259-p-800.webp 800w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556711_rectangle_42259-p-1080.webp 1080w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556711_rectangle_42259-p-1600.webp 1600w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556711_rectangle_42259.webp 1716w"
                  alt=""
                  className="gellary-item-img"
                />
              </div>
              <div
                data-w-id="fea8c9c1-59b7-f877-505f-70bb01c6f8b6"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d"
                }}
                className="gellary-item"
              >
                <img
                  src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255670f_rectangle_42258.webp"
                  loading="lazy"
                  sizes="(max-width: 1439px) 25vw, 360px"
                  srcSet="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255670f_rectangle_42258-p-500.webp 500w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255670f_rectangle_42258.webp 586w"
                  alt=""
                  className="gellary-item-img"
                />
              </div>
            </div>
          </div>
        </section>
       
        <section className="content-04">
          <div className="container-fluid">
            <div className="content-wrap-04">
              <h2 class="conten-title-04 heading-03 black-text">Our Mission and Vision</h2>
              <div

                className="content-iteem-05 _01"
              >
                <div className="item-thumbs-03">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 83vw, (max-width: 767px) 77vw, (max-width: 991px) 82vw, (max-width: 1279px) 43vw, 500px"
                    srcSet="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261-p-500.webp 500w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261-p-800.webp 800w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261.webp 1000w"
                    alt=""
                    className="item-thumb-img-03"
                  />
                </div>
                <div className="item-info-03 _04">

                  <h2 className="item-info-title-03 heading-04 black-text">
                    Mission
                  </h2>
                  <div className="content-items-01 _01">
                    <div className="content-info-item-01 _01">

                      <div className="info-item-text-01 body-01 black-text">
                        To empower individuals and organizations by providing comprehensive AI training that enhances skills, fosters innovation, and drives digital transformation.
                      </div>
                    </div>


                  </div>

                </div>
              </div>
              <div

                className="content-iteem-05 h-03"
              >
                <div className="item-info-03 _04">

                  <h2 className="item-info-title-03 heading-04 black-text">
                    Vision
                  </h2>
                  <div className="content-items-01 _01">
                    <div className="content-info-item-01 _01">

                      <div className="info-item-text-01 body-01 black-text">
                        To become a global leader in AI education, enabling people to harness the full potential of AI technologies for personal and professional growth.
                      </div>
                    </div>

                  </div>

                </div>
                <div className="item-thumbs-03">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 83vw, (max-width: 767px) 77vw, (max-width: 991px) 82vw, (max-width: 1279px) 39vw, 500px"
                    srcSet="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261-p-500.webp 500w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261-p-800.webp 800w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261.webp 1000w"
                    alt=""
                    className="item-thumb-img-03"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

 <section className="hero">

          <div className="container-fluid">
            <div className="hero-content">

              <div className="hero-infos">
                <div className="hero-info-wrap">
                  <div className="hero-raw-info">
                    <h2
                      data-w-id="2db43db6-2169-6b3b-913b-34ad97237a00"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d"
                      }}
                      className="hero-title heading-01 black-text"
                    >
                      Our Story
                    </h2>
                    <div
                      className="hero-info-text body-01 black-text"
                    >
                     Talentizo was founded with a singular goal in mind: to bridge the growing skills gap in the AI domain. As the rapid adoption of AI technology reshapes industries worldwide, the need for a skilled workforce becomes increasingly critical. Our founders, Manish Shrivastava and Megha Punia, recognized this gap and brought together their extensive experience in AI and HR to create Talentizo. Since our inception, we have achieved significant milestones, including partnerships with renowned institutions and successful program implementations that have transformed countless careers.


                    </div>
                    
                  </div>
                </div>
                <div className="hero-thumbs">
                  <div className="hero-bgs-wrap">
                    <div className="hero-bgs-01 _01" />
                    <div className="hero-bgs-01 _02" />
                  </div>
                  <div
                    data-w-id="6d4fb424-27a2-c8db-13b8-bcc7296ce72d"

                    className="hero-thumbb"
                  >
                    <img
                      src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a1_rectangle_21.webp"
                      loading="lazy"
                      sizes="(max-width: 479px) 99vw, (max-width: 767px) 87vw, (max-width: 991px) 90vw, (max-width: 1439px) 35vw, 455px"
                      srcSet="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a1_rectangle_21-p-500.webp 500w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a1_rectangle_21-p-800.webp 800w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a1_rectangle_21.webp 918w"
                      alt=""
                      className="hero-thumb-img"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="teams content-04 black-bg">
          <div className="container-fluid">
            <div className="teams-content">
              <h2 className="teams-title heading-03 ">
                Meet our Founders
              </h2>
              <div className="teams-list">
                <div
                  data-w-id="45b73215-5a3d-a0bd-873c-59db5b92fcb9"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d"
                  }}
                  className="feature-item-01"
                >

                  <div className="team-profile-wrap">
                    <img
                      src="images/Manish Srivastava.png"
                      loading="lazy"
                      alt="Blog Author Image"
                      className="blog-author-image"
                    />
                  </div>
                  <div className="teams-infos">

                    <div className="teams-name sub-heading-04 ">
                      Manish Shrivastava
                    </div>
                    <div className="teams-role caption-01">Co-Founder </div>
                    <p>Manish Shrivastava brings over 20 years of experience in software engineering and artificial intelligence. His career spans multiple successful ventures, including the establishment of Acompworld, a company specializing in AI and generative AI solutions. Manish's deep understanding of AI, combined with his passion for digital transformation, has driven him to create educational programs that empower individuals and organizations to harness the power of AI.</p>
                  </div>
                </div>
                <div
                  data-w-id="38119d03-af59-3b0d-393e-a0ee85fb7e19"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d"
                  }}
                  className="feature-item-01"
                >

                  <div className="team-profile-wrap">
                    <img
                      src="images/Megha Punia.png"
                      loading="lazy"
                      alt="Blog Author Image"
                      className="blog-author-image"
                    />
                  </div>
                  <div className="teams-infos">

                    <div className="teams-name sub-heading-04 ">
                      Megha Punia
                    </div>
                    <div className="teams-role caption-01">Co-Founder </div>
                    <p>Megha Punia is a seasoned HR and transformation leader with over 18 years of experience. Megha's background includes key roles in strategic HR, learning and development, and organizational transformation, with a focus on fostering a culture of innovation and continuous improvement. Her expertise in mentoring, training, and career planning ensures that Talentizo’s programs are not only technically robust but also aligned with the latest industry trends and practices.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>


        <section className="partners">
          <div className="container-fluid">
            <div className="partner-content">
              <h2 className="conten-title-04 heading-03 black-text">Partnerships and Collaborations</h2>
              <div className="partner-title-text body-02 black-text">
                We collaborate with leading institutions and organizations to deliver high-quality AI training programs. Our partners include educational institutions, government bodies, and industry leaders, helping us enhance the quality and reach of our programs.
              </div>
              {/* <div className="partner-list">
                <div className="partner-imgs">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a5_Frame%202%20(1).svg"
                    loading="lazy"
                    alt=""
                    className="partner-img"
                  />
                </div>
                <div className="partner-imgs">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a6_Frame%203%20(1).svg"
                    loading="lazy"
                    alt=""
                    className="partner-img"
                  />
                </div>
                <div className="partner-imgs">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a3_Frame%204%20(1).svg"
                    loading="lazy"
                    alt=""
                    className="partner-img"
                  />
                </div>
                <div className="partner-imgs">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a4_Frame%205%20(1).svg"
                    loading="lazy"
                    alt=""
                    className="partner-img"
                  />
                </div>
                <div className="partner-imgs">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566a2_Frame%206%20(1).svg"
                    loading="lazy"
                    alt=""
                    className="partner-img"
                  />
                </div>
              </div> */}
            </div>
            <div class="cta-buttons"><a href="/contact" class="button-01 cta-b w-button">Become a Partner</a></div>
          </div>
        </section>

      </>



      <section className="cta">
        <div className="container-fluid">
          <div className="cta-content">
            <h2

              className="cta-title"

            >
              We would love to hear from you! Whether you have a question about our programs, want to collaborate, or need more information, please reach out to us.

            </h2>
            <p>
            </p>
            <div className="cta-buttons"  >
              <a href="/contact" className="button-01 cta-b w-button">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer activelink='about' />
    </>
  );
};

export default About;