import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";
import FooterScript from "../components/footer-script";

const Home = () => {

    const messageEndRef = React.useRef(null);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <SEO
                title='Home | Telentizo'
                description=''
                keywords=''
            />
            <Header activelink='home' />

            <>
                {/* Hello world */}
                <section className="hero">

                    <div className="container-fluid">
                        <div className="hero-content">

                            <div className="hero-infos">
                                <div className="hero-info-wrap">
                                    <div className="hero-raw-info">
                                        <h2

                                            className="hero-title heading-01 black-text"
                                        >
                                            Empowering the Next Generation with AI Skills
                                        </h2>
                                        <div

                                            className="hero-info-text body-01 black-text"
                                        >
                                             Talentizo is dedicated to bridging the AI skills gap by offering comprehensive training programs for school students, college students, and working professionals.
                                        </div>
                                        <a
                                            href="/programs"

                                            className="button-01 w-button"
                                        >
                                            Explore our Programs
                                        </a>
                                    </div>
                                </div>
                                <div className="hero-thumbs">
                                    <div className="hero-bgs-wrap">
                                        <div className="hero-bgs-01 _01" />
                                        <div className="hero-bgs-01 _02" />
                                    </div>
                                    <div
                                        className="hero-thumbb"
                                    >
                                        <img
                                            src="images/services.webp"
                                            className="hero-thumb-img"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
               


                <section className="features-01 black-bg">
                    <div className="container-fluid">
                        <div className="features-content-01">
                            <h2
                                className="feature-title-01 heading-03 white-text"
                            >
                                Our Programs
                            </h2>
                            <div className="feature-list-01"
                            >
                                <div className="feature-item-01 _01">
                                    <div className="item-bulles-01">
                                        <img
                                            src="images/simple_three_1.svg"
                                            loading="lazy"
                                            alt=""
                                            className="item-bullet-count"
                                        />
                                        <div className="item-bullet-text sub-heading-04 white-text">
                                            For School Students
                                        </div>
                                    </div>
                                    <div className="feature-item-body-01">
                                        <div className="item-text-f01 body-01">
                                            Developing foundational AI skills and fostering early interest in technology.
                                        </div>
                                        <a

                                            href="/programs"
                                            className="button-02 w-inline-block"
                                        >
                                            <div className="button-02-text _03">Learn More</div>

                                        </a>
                                    </div>
                                </div>
                                <div className="feature-item-01 _02">
                                    <div className="item-bulles-01">
                                        <img
                                            src="images/simple_three_2.svg"
                                            className="item-bullet-count"
                                        />
                                        <div className="item-bullet-text sub-heading-04 white-text">
                                            For College Students
                                        </div>
                                    </div>
                                    <div className="feature-item-body-01">
                                        <div className="item-text-f01 body-01">
                                            Advanced AI concepts tailored for various disciplines to enhance academic and career prospects.
                                        </div>
                                        <a
                                            href="/programs"
                                            className="button-02 w-inline-block"
                                        >
                                            <div className="button-02-text _03">Learn More</div>

                                        </a>
                                    </div>
                                </div>
                                <div className="feature-item-01 _03">
                                    <div className="item-bulles-01">
                                        <img
                                            src="images/simple_three_3.svg"
                                            className="item-bullet-count"
                                        />
                                        <div className="item-bullet-text sub-heading-04 white-text">
                                            For Professionals
                                        </div>
                                    </div>
                                    <div className="feature-item-body-01">
                                        <div className="item-text-f01 body-01">
                                            Targeted AI training for leadership, workplace efficiency, and specialized IT development.
                                        </div>
                                        <a
                                            href="/programs"
                                            className="button-02 w-inline-block"
                                        >
                                            <div className="button-02-text _03">Learn More</div>

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="features-01">
                    <div className="container-fluid">
                        <div className="features-content">
                            <h2 className="feature-title heading-03 black-text">
                                Why Talentizo?
                            </h2>
                            <div className="fetures-list">
                                <div className="feature-item _01" >
                                    <img
                                        src="images/prcampaign_icon.svg"
                                        loading="lazy"
                                        alt=""
                                        className="feature-icon"
                                    />
                                    <h3 className="feature-item-title-03 sub-heading-03 black-text">
                                        Expert-Led and Tailored Courses
                                    </h3>
                                    <div className="text-block body-02 black-text">
                                        Learn from industry leaders with courses specifically designed for your sector, ensuring practical and relevant knowledge.
                                    </div>

                                </div>
                                <div className="feature-item _02">
                                    <img
                                        src="images/prcampaign_icon.svg"
                                        alt=""
                                        className="feature-icon"
                                    />
                                    <h3 className="feature-item-title-03 sub-heading-03 black-text">
                                        Hands-On and Flexible Learning
                                    </h3>
                                    <div className="text-block body-02 black-text">
                                        Engage in practical exercises with flexible online and in-person options to fit your schedule and learning preferences.
                                    </div>

                                </div>

                            </div>
                            <div className="fetures-list">

                                <div className="feature-item _02">
                                    <img
                                        src="images/content_creation_icon.svg"
                                        alt=""
                                        className="feature-icon"
                                    />
                                    <h3 className="feature-item-title-03 sub-heading-03 black-text">
                                        Comprehensive Curriculum and Resources
                                    </h3>
                                    <div className="text-block body-02 black-text">
                                        Gain industry-relevant skills through a comprehensive curriculum, with access to extensive course materials and additional resources.
                                    </div>

                                </div>
                                <div className="feature-item _01" >
                                    <img
                                        src="images/content_creation_icon.svg"
                                        alt=""
                                        className="feature-icon"
                                    />
                                    <h3 className="feature-item-title-03 sub-heading-03 black-text">
                                        Career Advancement
                                    </h3>
                                    <div className="text-block body-02 black-text">
                                        Equip yourself with cutting-edge AI skills to advance your career and stay competitive.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

               

                <section className="content-04">
                    <div className="container-fluid">
                        <div className="content-wrap-04">
                            <h2 class="conten-title-04 heading-03 black-text">Featured Program</h2>
                            <div className="content-iteem-05 _01">
                                <div className="item-thumbs-03">
                                    <img
                                        src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261.webp"
                                        loading="lazy"
                                        sizes="(max-width: 479px) 83vw, (max-width: 767px) 77vw, (max-width: 991px) 82vw, (max-width: 1279px) 43vw, 500px"
                                        srcSet="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261-p-500.webp 500w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261-p-800.webp 800w, https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b925566f7_rectangle_42261.webp 1000w"
                                        alt=""
                                        className="item-thumb-img-03"
                                    />
                                </div>
                                <div className="item-info-03 _04">

                                    <h2 className="item-info-title-03 heading-04 black-text">
                                    AI for Legal Professionals
                                    </h2>
                                    <div className="content-items-01 _01">
                                        <div className="content-info-item-01 _01">

                                            <div className="info-item-text-01 body-01 black-text">
                                            Our "AI for Future Legal Professionals" program is designed to introduce law students to the transformative power of AI within the legal field. This program provides a foundational understanding of AI, explores its practical applications in legal practice, and examines the ethical and legal implications of using AI. Participants will gain practical skills in AI tools for legal research, case management, and document drafting, positioning themselves as forward-thinking candidates in the legal industry.
                                            </div>
                                        </div>


                                    </div>
                                    <a href="/programs" className="button-01 cta-b another w-button">
                                    Learn More
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </>


            <section className="cta">
                <div className="container-fluid">
                    <div className="cta-content">
                        <h2

                            className="cta-title"

                        >
                            We would love to hear from you! Whether you have a question about our programs, want to collaborate, or need more information, please reach out to us.
                      
                        </h2>
                        <p>
                         </p>
                        <div className="cta-buttons"  >
                            <a href="/contact" className="button-01 cta-b w-button">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    );
};

export default Home;