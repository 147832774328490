import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";


import SEO from "../components/seo";
import axios from "axios";


const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organization_name, setOrganization] = useState("");
  const [description, setDescription] = useState("");
  const [showconfirmation, setShowConfirmation] = useState("No");
  const [isFetching, setisFetching] = useState(false);


  const submitForm = (event) => {
    setisFetching(true);
    event.preventDefault();
    axios
      .post("https://api.acolabz.com/askrishna/contactus", {
        full_name,
        organization_name,
        phone,
        email,
        description,
      })
      .then(data => {

        setisFetching(false);
        setShowConfirmation("Yes");
        alert("Successfull Sumit Your Request");
        setFullName("")
        setEmail("")
        setPhone("")
        setOrganization("")
        setDescription("")
      })
  };



  return (
    <>

      <SEO
        title='Contact'
        description='.'
        keywords=''
      />
      <Header activelink='contact' />
      <section className="contact-hero-01">
        <div className="bg-01 _01">
          <div className="bg01-right _01" />
          <div className="bg01-left _01" />
        </div>
        <div className="bg-02 _01">
          <div className="bg02-right" />
          <div className="bg02-left _01" />
        </div>
        <div className="container-fluid">
          <div className="contact-01-hero-content">
            <div className="style-heading">
              <h2
                data-w-id="6c7c6a90-4f69-8a0d-8a75-2bd4764dd019"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d"
                }}
                className="style-hero-title heading-01 black-text"
              >
                Get in Touch with Us
              </h2>
              <div class="hero-info-text body-01 black-text">We’d Love to Hear from You</div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-01">
        <div className="container-fluid ct1">
          <div className="contact-content">
            <div
              data-w-id="b545a887-3680-ba08-f2eb-141bd0c0f656"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d"
              }}
              className="contact-addreses-01 lemon-bg"
            >
              <div className="address-item">
                <img
                  src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556677_Group%201000019015.svg"
                  loading="lazy"
                  alt=""
                  className="address-item-icon"
                />
              </div>
              <h3 className="address-item-title sub-heading-03">Mail</h3>
              <div className="address-item-text body-01">info@talentizo.ai</div>
              
            </div>
            <div
              data-w-id="c846cdb4-276b-5f7e-ed20-cdd3875402c1"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d"
              }}
              className="contact-addreses-01 purple-bg"
            >
              <div className="address-item">
                <img
                  src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556678_Group%201000019018.svg"
                  loading="lazy"
                  alt=""
                  className="address-item-icon"
                />
              </div>
              <h3 className="address-item-title sub-heading-03">Phone</h3>
              <div className="address-item-text body-01">+91- 900000000</div>
              
            </div>
            <div
              data-w-id="1dadd51c-8d35-c973-5ec3-ee32272ad475"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                opacity: 1,
                transformStyle: "preserve-3d"
              }}
              className="contact-addreses-01 brown-bg"
            >
              <div className="address-item">
                <img
                  src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556679_Group%201000019019.svg"
                  loading="lazy"
                  alt=""
                  className="address-item-icon"
                />
              </div>
              <h3 className="address-item-title sub-heading-03">Address</h3>
              <div className="address-item-text body-01">F-7, Serenia, IHDP Business Park,
              Sector 127, Noida (UP) 201303</div>
             
            </div>
          </div>
          <div
            data-w-id="ad7635d4-5be4-0cd5-d9d7-e88c11d5f0ca"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              opacity: 1,
              transformStyle: "preserve-3d"
            }}
            className="form-block-01 w-form"
          >
            <div className="form-head-01">
              <h2 className="form-head-title-01 sub-heading-03 black-text">
                Get in Touch
              </h2>
            </div>
            <form action="" onSubmit={submitForm} className="contact-form-01">
              <div className="form-group-01">
                <div className="field-group-01">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255668a_User.svg"
                    loading="lazy"
                    alt=""
                    className="f-envelope _02"
                  />

                  <input
                    type="text"
                    class="form-field-01 _03 w-input"
                    id="txtName"
                    value={full_name}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                    aria-describedby="Enter Name"
                    placeholder="Please Enter Your Name"
                  />
                </div>
                <div className="field-group-01">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255668d_Frame%20(9).svg"
                    loading="lazy"
                    alt=""
                    className="f-envelope _02"
                  />
                  <input
                    type="text"
                    class="form-field-01 _03 w-input"
                    id="txtName"
                    value={organization_name}
                    onChange={(e) => setOrganization(e.target.value)}
                    required
                    aria-describedby="Enter Organization"
                    placeholder="Enter Organization"
                  />
                </div>
              </div>
              <div className="form-group-01">
                <div className="field-group-01">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255668e_Frame%20(8).svg"
                    loading="lazy"
                    alt=""
                    className="f-envelope _02"
                  />
                  <input
                    type="number"
                    class="form-field-01 _03 w-input"
                    id="txtName"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    aria-describedby="Enter Phone"
                    placeholder="Enter Phone"
                  />
                </div>
                <div className="field-group-01">
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255667a_Envelope.svg"
                    loading="lazy"
                    alt=""
                    className="f-envelope _02"
                  />

                  <input
                    type="email"
                    class="form-field-01 _03 w-input"
                    id="txtEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                    required
                    placeholder="Enter Your Email"
                  />
                </div>
              </div>
              <div className="form-group-01">
                <div className="field-group-01">
                  <grammarly-extension
                    data-grammarly-shadow-root="true"
                    className="dnXmp"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      pointerEvents: "none"
                    }}
                  />
                  <grammarly-extension
                    data-grammarly-shadow-root="true"
                    className="dnXmp"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      pointerEvents: "none"
                    }}
                  />
                  <img
                    src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b9255667c_ChatCircleDots.svg"
                    loading="lazy"
                    alt=""
                    className="f-envelope _033"
                  />

                  <textarea
                    name=""
                    id="txtMessage"
                    cols="30"
                    rows="4"
                    class="form-field-01 _033 w-input"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="submit-wrap">
                <input
                  type="submit"
                  data-wait="Please wait..."
                  className="button-01 ct2 w-button"
                  defaultValue="Send Message"
                />
              </div>
            </form>
            <div
              className="w-form-done"
              tabIndex={-1}
              role="region"
              aria-label="Email Form success"
            >
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div
              className="w-form-fail"
              tabIndex={-1}
              role="region"
              aria-label="Email Form failure"
            >
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </section>


     

      <Footer activelink='contact' />
    </>
  );
};

export default Contact;