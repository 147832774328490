import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ activelink }) => {
  return (
    <>
      <footer className="py-5">
        
     
        <section className="footer">
       
          <div className="container-fluid">
            <div className="footer-content">

              <div className="footer-copyright">
                <p className="copyright-text body-02 black-text">
                  © All rights reserved{" "}
                  <a
                    href="#"
                    target="_blank"
                    className="cp-link"
                  >
                    Talentizo
                  </a>
                
                </p>
                <div>
         <a href="/"> Home </a>|<a href="/about"> About Us </a>| <a href="/programs">Programs</a> | <a href="/contact">Contact Us</a>
        </div>
                <div className="footer-socials">
                  <a
                    href="#"
                    target="_blank"
                    className="social-link op70 w-inline-block"
                    style={{ opacity: 1 }}
                  >
                    <img
                      src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556674_Frame%20(3).svg"
                      loading="lazy"
                      alt=""
                      className="social-img"
                    />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="social-link op70 w-inline-block"
                    style={{ opacity: 1 }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512" aria-hidden="true" class="_24ydrq0 _1286nb11m _1286nb12q8"><path d="M298.158 216.797 484.663 0h-44.196L278.525 188.242 149.182 0H0l195.592 284.655L0 512h44.198l171.016-198.79L351.809 512h149.182L298.147 216.797zm-60.536 70.366-19.818-28.345L60.124 33.272h67.885L255.26 215.295l19.817 28.345 165.411 236.601h-67.886l-134.98-193.067z"></path></svg>
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="social-link op70 w-inline-block"
                    style={{ opacity: 1 }}
                  >
                    <img
                      src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556673_Frame%20(5).svg"
                      loading="lazy"
                      alt=""
                      className="social-img"
                    />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="social-link op70 w-inline-block"
                    style={{ opacity: 1 }}
                  >
                    <img
                      src="https://cdn.prod.website-files.com/667d12d98a8ab07b925565d9/667d12d98a8ab07b92556672_Frame%20(6).svg"
                      loading="lazy"
                      alt=""
                      className="social-img"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
         
        </section>


      </footer>
    </>
  );
};

export default Footer;
