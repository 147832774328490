import React from "react";
import { Link } from "react-router-dom";

const FooterScript = ({ activelink }) => {
  return (
    <>
       <script src="/js/5cb246508.js"></script>
    </>
  );
};

export default FooterScript;
